/** @jsx jsx */
import { jsx } from "theme-ui"
import Inner from "~components/Layout/inner"
import Content from "~components/Layout/content"

import ProjectsMDX from "~translations/projects.mdx"

import { BackgroundPolygon2 } from "~compounds/Background"
import type { PageProps } from "~types/page"

const Projects = ({ offset, factor = 2 }: PageProps) => (
  <div>
    <BackgroundPolygon2 offset={offset} factor={factor} />
    <Content speed={0.4} offset={offset + 0.2} factor={factor}>
      <Inner>
        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
            h2: { gridColumn: `-1/1`, color: `white !important` },
          }}
        >
          <ProjectsMDX />
        </div>
      </Inner>
    </Content>
  </div>
)

export default Projects
