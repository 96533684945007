/** @jsx jsx */
import { jsx } from "theme-ui"
import Inner from "~components/Layout/inner"
import Content from "~components/Layout/content"
import Footer from "~components/footer"

import ContactMDX from "~translations/contact.mdx"
import { PageProps } from "~types/page"

import { BackgroundPie2 } from "~compounds/Background"

const Contact = ({ offset, factor = 1 }: PageProps) => (
  <div>
    <BackgroundPie2 offset={offset} factor={factor} />
    <Content speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <ContactMDX />
      </Inner>
      <Footer />
    </Content>
  </div>
)

export default Contact
