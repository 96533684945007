/** @jsx jsx */
import { jsx } from "theme-ui"
import Inner from "~components/Layout/inner"
import Content from "~components/Layout/content"

import AboutMDX from "~translations/about.mdx"

import { BackgroundPolygon } from "~compounds/Background"
import { PageProps } from "~types/page"

// import wordArt from "~src/assets/images/wordcloud-pie.svg"

const About = ({ offset, factor = 1 }: PageProps) => (
  <div>
    <BackgroundPolygon offset={offset} factor={factor} />
    <Content speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <AboutMDX />
      </Inner>
      {/* <Inner sx={{ display: [`block`, `block`, `block`, `block`, `flex`, `flex`], alignItems: "center" }}>
        <div sx={{ flex: 2 }}>
          <AboutMDX />
        </div>
        <div
          sx={{
            flex: 1,
            textAlign: "center",
            marginBottom: -40,
          }}
        >
          <img
            alt=""
            src={wordArt}
            sx={{
              width: 400,
              maxWidth: "90%",
            }}
          />
        </div>
      </Inner> */}
    </Content>
  </div>
)

export default About
