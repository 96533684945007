/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"

import Divider from "~components/Layout/divider"
import { UpDown, UpDownWide, waveAnimation } from "~components/Styles/animations"
import Svg from "~components/Icon/svg"

import type { PageProps } from "~types/page"
import PieSteam from "~compounds/PieSteam"

const BackgroundColorful = ({ offset, factor }: PageProps) => {
  return (
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDown>
        <Svg icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
        <Svg icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Svg icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
        <Svg icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
        <Svg icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
        <Svg icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
        <Svg icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
        <Svg icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
        <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <Svg icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <Svg icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
      <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <Svg icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
      <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
      <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
    </Divider>
  )
}

const BackgroundPlain = ({ offset, factor = 1, ...rest }: PageProps) => {
  return (
    <Divider speed={0.1} offset={offset} factor={factor} {...rest}>
      <UpDown>
        <Svg icon="upDown" hiddenMobile width={8} color="icon_darker" left="70%" top="20%" />
        <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
      </UpDown>
      <UpDownWide>
        <Svg icon="triangle" width={12} stroke color="icon_darker" left="95%" top="50%" />
        <Svg icon="circle" width={6} color="icon_darker" left="85%" top="15%" />
        <Svg icon="upDown" hiddenMobile width={8} color="icon_darker" left="45%" top="10%" />
      </UpDownWide>
      {/* <Svg icon="circle" width={6} color="icon_darker" left="4%" top="20%" /> */}
      <Svg icon="circle" width={12} color="icon_darker" left="60%" top="60%" />
      <Svg icon="box" width={12} color="icon_darker" left="20%" top="30%" />
      <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
    </Divider>
  )
}

const BackgroundPie = ({ offset, factor = 1 }: PageProps) => {
  return (
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDown>
        {/* <Svg icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" /> */}
        <PieSteam sx={{ left: "65%", top: "40%" }} />
        <Svg icon="hexa" width={48} stroke color="icon_darkest" left="60%" top="70%" />
        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Svg icon="arrowUp" hiddenMobile width={16} color="icon_darkest" left="80%" top="10%" />
        <Svg icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
        <Svg icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
        <Svg icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
        <Svg icon="cross" width={16} stroke color="icon_darkest" left="28%" top="15%" />
        <Svg icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
        <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <Svg icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <Svg icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
      <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <Svg icon="box" hiddenMobile width={64} color="icon_darker" left="5%" top="90%" />
      <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
      <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
    </Divider>
  )
}

const BackgroundPie2 = ({ offset, factor = 1 }: PageProps) => {
  return (
    <Fragment>
      <Divider fill="divider" speed={0.2} offset={offset} factor={factor}>
        <div sx={{ position: `absolute`, bottom: 0, width: `full`, transform: `matrix(1, 0, 0, -1, 0, 0)` }}>
          <div
            sx={{
              position: `relative`,
              height: `full`,
              svg: { width: `100%`, height: `40vh` },
              path: { animation: waveAnimation(`20s`) },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" id="contact-wave" viewBox="0 0 800 338.05" preserveAspectRatio="none">
              <path>
                <animate
                  attributeName="d"
                  values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                  repeatCount="indefinite"
                  dur="30s"
                />
              </path>
            </svg>
          </div>
        </div>
      </Divider>
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
          <Svg icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
          <PieSteam sx={{ left: "65%", top: "35%" }} />
        </UpDown>
        <UpDownWide>
          <Svg icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
          <Svg icon="circle" width={6} color="icon_brightest" left="85%" top="15%" />
          <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
        </UpDownWide>
        <Svg icon="circle" width={6} color="icon_brightest" left="4%" top="20%" />
        <Svg icon="circle" width={12} color="icon_darkest" left="60%" top="60%" />
        <Svg icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <Svg icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
      </Divider>
    </Fragment>
  )
}

const BackgroundPolygon = ({ offset, factor = 1 }: PageProps) => {
  return (
    <Fragment>
      <Divider bg="divider" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={offset} factor={factor} />
      <Divider speed={0.1} offset={offset + 0.2} factor={factor}>
        <UpDown>
          <Svg icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
          <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
          <Svg icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
          <Svg icon="upDown" hiddenMobile width={24} color="icon_orange" left="80%" top="80%" />
        </UpDown>
        <UpDownWide>
          <Svg icon="arrowUp" hiddenMobile width={16} color="icon_purple" left="5%" top="80%" />
          <Svg icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
          <Svg icon="circle" hiddenMobile width={6} color="icon_brightest" left="85%" top="15%" />
          <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
        </UpDownWide>
        <Svg icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
        <Svg icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
        <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <Svg icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <Svg icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
      </Divider>
    </Fragment>
  )
}

const BackgroundPolygon2 = ({ offset, factor = 1 }: PageProps) => {
  return (
    <Fragment>
      <Divider
        bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
        speed={-0.2}
        offset={offset}
        // offset={offset + 0.1}
        factor={factor}
      />
      <Divider speed={0.1} offset={offset} factor={factor}>
        <UpDown>
          <Svg icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
          <Svg icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <Svg icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
          <Svg icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%" />
        </UpDown>
        <UpDownWide>
          <Svg icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%" />
          <Svg icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%" />
          <Svg icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
          <Svg icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%" />
          <Svg icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
          <Svg icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%" />
        </UpDownWide>
        <Svg icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
        <Svg icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <Svg icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <Svg icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
        <Svg icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
      </Divider>
    </Fragment>
  )
}

export { BackgroundColorful, BackgroundPie, BackgroundPie2, BackgroundPolygon, BackgroundPolygon2, BackgroundPlain }
