import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ProjectCard from "~compounds/ProjectCard";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Projects`}</h2>
    <ProjectCard title="Web & Mobile App" link="/projects" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Build customizable web and mobile applications. From online shop to internal system. We are artist.
    </ProjectCard>
    <ProjectCard title="Software Moderni&shy;zation" link="/projects" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Re-innovate legacy software while preserving features in need. We are surgeon.
    </ProjectCard>
    <ProjectCard title="Tech Consulting" link="/projects" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Explore market opportunities with emerging technology. We are fortune teller.
    </ProjectCard>
    <ProjectCard title="Any crazy pies" link="/projects" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  The only limitation is your imagination. We are open for idea, e.g. bbq pork sushi. We are innovator.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      