import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    {
      /*
      > The English language can not fully capture the depth and complexity of my thoughts. So I'm incorporating Emoji into my speech to better express myself. Winky face.
      You know the way you feel when you see a picture of two otters holding hands? That's how you're gonna feel every day. My mother cried the day I was born because she knew she’d never be prettier than me. You should make me your campaign manager. I was born for politics. I have great hair and I love lying. Captain? The kids want to know where Paulie the Pigeon is. I told them he got sucked up into an airplane engine, is that all right?
      Two Pies is a leading provider of cutting-edge technologies and services, offering scalable solutions for businesses of all sizes. Founded by a group of friends who started by scribbling their ideas on a piece of paper, today we offer smart, innovative services to dozens of clients worldwide. Why not join our fast growing client base? Get in touch today to learn more.
      */
    }
    <p>{`We're developers, artists, scientists, sociologists, and foodies.`}</p>
    <p>{`Developing an IT solution is same as making a pie. Get ingredients, blend, bake, and enjoy it.`}</p>
    <p>{`We have grate experience in creating different pies, from local to international firm, public facing or private kitchen.`}</p>
    <p>{`We are proud of our pies, which making our clients satisfied, and helping the world.`}</p>
    <p><a parentName="p" {...{
        "href": "/#contact"
      }}>{`Contact us`}</a>{` if you want a 🥧, or two.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      