/** @jsx jsx */
import { jsx } from "theme-ui"
import Inner from "~components/Layout/inner"
import Content from "~components/Layout/content"
import Intro from "~translations/intro.mdx"

import { BackgroundPie } from "~compounds/Background"

import { PageProps } from "~types/page"

const Hero = ({ offset, factor = 1 }: PageProps) => (
  <div>
    <BackgroundPie offset={offset} factor={factor} />
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <Intro />
      </Inner>
    </Content>
  </div>
)

export default Hero
