import Hero from "~containers/hero"
import Projects from "~containers/projects"
import About from "~containers/about"
import Contact from "~containers/contact"

const sites = [
  { Component: Hero, factor: 1, offset: -1 },
  { Component: About, factor: 1, offset: -1 },
  { Component: Projects, factor: 2, offset: -1 },
  { Component: Contact, factor: 1, offset: -1 },
]

// WARNING: modify sites offset here
const totalPages = sites.reduce((acc, site) => {
  site.offset = acc
  acc += site.factor
  return acc
}, 0)

export { sites, totalPages }
