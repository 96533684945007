/** @jsx jsx */
import { jsx } from "theme-ui"
import { Steam } from "~components/Steam"
import Svg from "~components/Icon/svg"

type P = {
  className?: string
}

const PieSteam = ({ className }: P) => (
  <div sx={{ position: "relative", zIndex: -1, width: 48, height: 48 }} className={className}>
    <Svg icon="pieNoSteam" width={48} color="peru" left="0" top="0" />
    <Steam />
  </div>
)

export default PieSteam
