/** @jsx jsx */
import { keyframes, css, jsx } from "@emotion/react"
// import { jsx } from "theme-ui"
import { Fragment } from "react"

const steamKeyframes = keyframes`
    0% { top: -20px; opacity: .075; }
    20% { top: -40px; opacity: 0.3; }
    40% { top: -60px; opacity: 0.5; }
    60% { top: -80px; opacity: 0.3; }
    80% { top: -100px; opacity: .075; }
    100% { top: -120px; opacity: 0; }
`

const steamAnimation = (delay: string) => css`
  position: relative;
  top: -40px;
  left: 125px;
  opacity: 0;
  animation: ${steamKeyframes} 5s linear infinite ${delay};

  & > span {
    position: absolute;
    border-right: 0px solid transparent;
    border-top: 2px solid #d6d2d2;
    border-left: 5px solid #d6d2d2;
    border-bottom: 2px solid transparent;
    width: 40px;
    height: 70px;
    z-index: 4;
    border-radius: 80% 0 10% 10%;
    filter: blur(7px);
  }
`

const Steam = () => {
  return (
    <Fragment>
      <div css={steamAnimation("2s")}>
        <span
          css={css`
            top: 0;
            left: -25px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -45px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
      <div css={steamAnimation("4s")}>
        <span
          css={css`
            top: 0;
            left: -25px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -45px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
      <div css={steamAnimation("0s")}>
        <span
          css={css`
            top: 0;
            left: -25px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -45px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
      <div css={steamAnimation("2s")}>
        <span
          css={css`
            top: 0;
            left: -15px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -65px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
      <div css={steamAnimation("4s")}>
        <span
          css={css`
            top: 0;
            left: -15px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -65px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
      <div css={steamAnimation("0s")}>
        <span
          css={css`
            top: 0;
            left: -15px;
            transform: rotate(168deg) skewY(10deg);
          `}
        />
        <span
          css={css`
            top: 40px;
            left: -65px;
            transform: rotate(315deg) skewY(10deg);
          `}
        />
      </div>
    </Fragment>
  )
}

export { Steam }
