import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Two Pies: IT is art and science`}</h1>
    <p>{`IT solutions are pies, which should be essential and enjoyable.`}<br parentName="p"></br>{`
`}{`We don't cook. We code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      