import React from "react"
import { Parallax } from "@react-spring/parallax"
import Layout from "~components/Layout/layout"
import { sites, totalPages } from "../config"

const Home = () => (
  <Layout>
    <Parallax pages={totalPages}>
      {sites.map(({ Component, offset, factor }) => (
        <Component key={offset} offset={offset} factor={factor} />
      ))}
    </Parallax>
  </Layout>
)

export default Home
